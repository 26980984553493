import React from 'react'

import NewsComponent from './components/NewsComponent'

export default function App() {
  return (
    <div>
        <NewsComponent />
    </div>
  )
}
