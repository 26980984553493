import React, { useEffect, useState } from 'react';
import axios from 'axios';

function NewsComponent() {
 const [newsData, setNewsData] = useState([]);
 const [fromDate, setFromDate] = useState('2024-01-01');
 const [toDate, setToDate] = useState('2024-1-31');
 const [query, setQuery] = useState('microsoft');

 useEffect(() => {
   const fetchData = async () => {
     try {
       const result = await axios(
         `https://newsapi-api.onrender.com/top-headlines?from=${fromDate}&to=${toDate}&q=${query}`,
       );
       setNewsData(result.data.articles);
     } catch (err) {
       console.error(err);
     }
   };

   fetchData();
 }, [fromDate, toDate, query]);

 const handleSubmit = (event) => {
   event.preventDefault();
 };

 return (
   <div>
     <form onSubmit={handleSubmit}>
       <label>
         From:
         <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
       </label>
       <label>
         To:
         <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
       </label>
       <label>
         Search:
         <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} />
       </label>
       <button type="submit">Fetch News</button>
     </form>
     {newsData.map((article, index) => (
       <div key={index}>
         <h2>{article.title}</h2>
         <p>{article.description}</p>
         <a href={article.url}>Read more...</a>
       </div>
     ))}
   </div>
 );
}

export default NewsComponent;
